import IcInfo, { IcRideAttr } from './IcInfo';
import { Capacity, BreakingPoint } from '../../components/types';

import RideBase, { AttrsBase } from './RideBase';
import Ride, { getRideUid } from './Ride';
import dayjs from 'dayjs';

export type AttrsBaseTrip = AttrsBase & {
    icInfo?: IcRideAttr[];
    suggestions?: AttrSuggestion[];
    uid?: string;
    id: string;
    suggestionType: 'FULL' | 'PARTIAL';
    rideUuid: string;
    lineColor?: string;
    type?: 'DIRECT' | 'INTERCONNECTION';
    lineDetails?: { code: string; color: string };
};

export type AttrSuggestion = AttrsBase & {
    uid: string;
    capacity: Capacity;
    gettingStatus?: string;
    gettingMessage?: string | null;
    disabled?: boolean;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    rides?: (Omit<AttrsBase, 'destinationStationCode' | 'startStationCode'> & {
        rideUuid: string;
        lineColor: string;
        sortOrder?: number;
        lineNumber?: string;
        tripNumber?: number;
        isPassed?: boolean;
        destinationStationCode?: string;
        startStationCode?: string;
    })[];
};

export type AttrsBaseTripCustom = AttrSuggestion & {
    isCustom: boolean;
    initTripInfo?: {
        startStationCode: string;
        destinationStationCode: string;
        startStationName: string;
        destinationStationName: string;
        arrivalTime: string;
        departureTime: string;
    };
    breakingPoint?: BreakingPoint;
};

export default class Trip extends RideBase {
    icInfo: IcInfo;
    suggestions!: SuggestionsCollection;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    status?: 'CANCELLED' | 'PAID';
    id!: string;
    rideId!: string;
    rideUid!: string;
    rideUuid!: string;
    uid!: string;
    rides!: Ride[];
    lineColor?: string;
    /** departure more than DEPARTURE_TIME_THRESHOLD hours, arrival more than ARRIVAL_TIME_THRESHOLD */
    blockToRebook: boolean;
    arrivalTimeExceeded: boolean;
    departureTimeExceeded: boolean;

    private DEPARTURE_TIME_THRESHOLD = 72;
    private ARRIVAL_TIME_THRESHOLD = 24;

    private getBlockToRebook(): {
        arrivalTimeExceeded: boolean;
        departureTimeExceeded: boolean;
        block: boolean;
    } {
        const formatArrival = dayjs
            .utc(this.arrivalTime)
            .utcOffset(this.arrivalUtcOffset);
        const formatDeparture = dayjs
            .utc(this.departureTime)
            .utcOffset(this.departureUtcOffset);

        const timeNow = dayjs().utcOffset(this.arrivalUtcOffset);
        // Check if departure time is > DEPARTURE_TIME_THRESHOLD hours in the past
        const departureTimeExceeded =
            timeNow.diff(formatDeparture, 'hours', true) >
            this.DEPARTURE_TIME_THRESHOLD;
        // Check if arrival time is > ARRIVAL_TIME_THRESHOLD hours in the past
        const arrivalTimeExceeded =
            timeNow.diff(formatArrival, 'hours', true) >
            this.ARRIVAL_TIME_THRESHOLD;
        // No rebooking allowed if either of these conditions are true
        return {
            arrivalTimeExceeded,
            departureTimeExceeded,
            block: departureTimeExceeded || arrivalTimeExceeded,
        };
    }

    constructor(object: AttrsBaseTrip) {
        const icRides = object.icInfo;
        super(object);
        Object.assign(this, object);
        this.icInfo = new IcInfo(...(icRides || []));
        if (object.type === 'DIRECT') {
            this.rideUid = getRideUid(object);
            this.lineColor = object.lineDetails?.color || '';
        }

        if (Array.isArray(object.suggestions)) {
            this.suggestions = new SuggestionsCollection(object.suggestions);
        }
        const blockToRebook = this.getBlockToRebook();
        this.blockToRebook = blockToRebook.block;
        this.arrivalTimeExceeded = blockToRebook.arrivalTimeExceeded;
        this.departureTimeExceeded = blockToRebook.departureTimeExceeded;
    }
}

export class Suggestion extends RideBase {
    uid!: string;
    capacity!: Capacity;
    gettingStatus?: string;
    gettingMessage?: string | null;
    disabled?: boolean;
    suggestionTripType?: 'DIRECT' | 'INTERCONNECTION';
    icInfo: IcInfo;
    rideUid!: string;
    rides!: Ride[];

    constructor(trip: AttrSuggestion) {
        super(trip);
        Object.assign(this, trip);
        const icRides = trip.rides;
        this.icInfo = new IcInfo(...(icRides || []));
    }

    ifOriginalRide({
        originRideId,
        originRideUid,
        originRideUuid,
        originDestinationStationId,
        originStartStationId,
    }: OriginTripIds) {
        let origin = false;

        if (
            (originRideId && this.rideUid === originRideId) ||
            (originRideUid && this.rideUid === originRideId) ||
            (originRideUuid && this.rideUid === originRideId)
        ) {
            origin = true;
        }

        this.rides.some((ride: Ride) => {
            if (
                (originRideId && ride.rideUid === originRideId) ||
                (originRideUid && ride.rideUid === originRideId) ||
                (originRideUuid && ride.rideUid === originRideId)
            ) {
                origin = true;
            } else if (
                (originRideId && ride.uid?.includes(originRideId)) ||
                (originRideUid && ride.uid?.includes(originRideUid)) ||
                (originRideUuid && ride.uid?.includes(originRideUid))
            ) {
                origin = true;
            } else if (
                (originRideId && ride.rideUuid === originRideId) ||
                (originRideUid && ride.rideUuid === originRideId) ||
                (originRideUuid && ride.rideUuid === originRideId)
            ) {
                origin = true;
            } else {
                origin = false;
            }

            return false;
        });

        if (
            origin &&
            originStartStationId !== this.startStationId &&
            originDestinationStationId !== this.destinationStationId
        ) {
            origin = true;
        }

        return origin;
    }
}

export class CustomSuggestion extends Suggestion {
    disabled?: boolean;
    isCustom?: boolean;
    initTripInfo?: {
        startStationCode: string;
        destinationStationCode: string;
        startStationName: string;
        destinationStationName: string;
        arrivalTime: string;
        departureTime: string;
    };
    breakingPoint?: BreakingPoint;
    constructor(object: AttrsBaseTripCustom) {
        super(object);
        Object.assign(this, object);
    }
}

export class SuggestionsCollection extends Array<Suggestion> {
    constructor(arr: AttrSuggestion[]) {
        let suggestions: any[] = [];
        // filters out suggestions with invalid data
        const validSuggestions = arr.filter((sg) => {
            const isInvalidRides = sg.rides?.some((ride) => {
                return ride.arrivalTime === null || ride.departureTime === null;
            });
            return (
                sg.arrivalTime !== null &&
                sg.departureTime !== null &&
                !isInvalidRides
            );
        });
        suggestions = validSuggestions.map((sg) => {
            return new Suggestion(sg);
        });

        super(...suggestions);
    }
    static get [Symbol.species]() {
        return Array;
    }
}

export type OriginTripIds = {
    originRideId: string;
    originRideUid: string;
    originRideUuid: string;
    originDestinationStationId: string;
    originStartStationId: string;
};
