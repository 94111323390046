import { Redirect, useHistory } from 'react-router-dom';
import { Button, Infobox } from '@flixbus/honeycomb-react';
import { useAppState } from '../../dataLayer/AppState';
import { SubPage, SubPageContent, SubPageFooter } from '../SubPage/';
import SummaryBody from './SummaryBody';
import BatchSummaryBody from './BatchSummaryBody';
import { useRouteMatch } from 'react-router-dom';

import { useTranslate, Translate } from '../../system/i18n/useTranslate';
import useRebook from '../../dataLayer/useRebook';
import { BatchAltTripTracking, ddSameTripError } from '../../DataDog/actions';

export interface RebookSummaryProps {
    rideUuid: string;
    currentPage?: number;
    resultsPerPage?: number;
}

const RebookSummary = ({ rideUuid }: RebookSummaryProps) => {
    const translate: Translate = useTranslate();
    const history = useHistory();
    const [appState] = useAppState();
    const {
        options = {},
        notify,
        trackers = [],
        singleOptions = {},
    } = appState;

    const optionsMap = Object.entries(options);
    const mutation = useRebook(rideUuid);
    const match = useRouteMatch();

    const ordersToProcessed = Object.entries(singleOptions).map(([, value]) => {
        return value.order;
    });

    const onModalClose = () => {
        history.goBack();
    };

    const rebook = () => {
        const replaceTrips = optionsMap.map((option) => {
            return { orderId: option[0], ...option[1] };
        });
        mutation.mutate({ notify, replaceTrips });
        for (const tracker of trackers) {
            const replaceTrips =
                tracker instanceof BatchAltTripTracking
                    ? options[tracker.orders[0]]
                    : options[tracker.order];
            tracker.track('rebook', {
                replaceTrips,
            });
        }
    };
    const hasSameTripOption = () => {
        const flag = optionsMap.some((option) => {
            return option[1].originTripUuid === option[1].replaceTripUuid;
        });
        if (flag) {
            ddSameTripError(options);
        }
        return flag;
    };

    return (
        <SubPage
            title={translate('summary__rebook_orders')}
            onClose={onModalClose}
            extraClasses="rbk-sub-page--wide"
        >
            <SubPageContent>
                {ordersToProcessed.length === 0 ? (
                    <Infobox appearance="danger">
                        There are no options selected
                    </Infobox>
                ) : (
                    <>
                        {!match.path.includes('batch') ? (
                            <>
                                <SummaryBody
                                    orders={ordersToProcessed}
                                    selectedOptions={options}
                                    lastStep={true}
                                />
                            </>
                        ) : (
                            <BatchSummaryBody
                                orders={ordersToProcessed}
                                lastStep={true}
                            />
                        )}
                        {hasSameTripOption() && (
                            <Infobox small appearance="warning">
                                {translate(
                                    'summary-page.warning.same-trip-option'
                                )}
                            </Infobox>
                        )}
                    </>
                )}
            </SubPageContent>
            {ordersToProcessed.length !== 0 ? (
                <SubPageFooter justify="center">
                    <Button
                        appearance="primary"
                        onClick={rebook}
                        loading={mutation.isLoading}
                        disabled={mutation.isLoading || hasSameTripOption()}
                    >
                        {translate('rebook_passengers')}
                    </Button>
                    {mutation.isSuccess && (
                        <Redirect
                            to={{
                                pathname: `/${rideUuid}/status`,
                                state: { data: { ...mutation.data } },
                            }}
                        />
                    )}
                    {mutation.isError && (
                        <Redirect
                            to={{
                                pathname: `/${rideUuid}/error`,
                                state: { error: String(mutation.error) },
                            }}
                        />
                    )}
                </SubPageFooter>
            ) : null}
        </SubPage>
    );
};

export default RebookSummary;
